import React from 'react'
import styled from 'styled-components'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share'
import './SomeButtons.css'
import { breakpoints } from '../../styles/variables'
import { Button } from '@material-ui/core'

interface SomeCardProps {
  url: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    flex-direction: row;
  }
`

const Text = styled.div`
  font-family: 'Source Code Pro', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-left: 15px;
`

const SomeButtons: React.FC<SomeCardProps> = ({ url }) => {
  return (
    <Container>
      <FacebookShareButton resetButtonStyle={false} className={'button'} url={url}>
        <FacebookIcon size={25} style={{ borderRadius: '50%' }} />
        <Text>Facebook</Text>
      </FacebookShareButton>
      <TwitterShareButton resetButtonStyle={false} className={'button'} url={url}>
        <TwitterIcon size={25} style={{ borderRadius: '50%' }} />
        <Text>Twitter</Text>
      </TwitterShareButton>

      <WhatsappShareButton resetButtonStyle={false} className={'button'} url={url}>
        <WhatsappIcon size={25} style={{ borderRadius: '50%' }} />
        <Text>WhatsApp</Text>
      </WhatsappShareButton>
    </Container>
  )
}

export default SomeButtons
