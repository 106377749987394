import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../../styles/variables'

const FavoriteButtonContainer = styled.div.attrs((props: { marginTop: number; inverse: boolean }) => props)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 43px;
  background: ${(props) => (props.inverse ? colors.white : colors.black)};
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || '0'}px;
  padding: 12px 30px;
`

const Text = styled.div.attrs((props: { inverse: boolean; favorited: boolean }) => props)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-left: 14px;
  color: ${(props) => (props.favorited ? colors.deiRed : props.inverse ? colors.black : colors.white)};
  margin-left: 20px;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    font-size: 18px;
    line-height: 22px;
    margin-left: 5px;
  }
`
interface Props {
  id: number
  series: boolean
  marginTop?: number
  inverse?: boolean
  mediaSeriesId: string
  xmlId?: string
}

const CopyLinkButton: React.FC<Props> = ({ id, mediaSeriesId, xmlId, marginTop, inverse, series }) => {
  const [copySuccess, setCopySuccess] = useState('Kopioi')

  const handleCopyClick = async () => {
    const itemId = xmlId ?? id
    const linkText = series
      ? `https://deiplus.fi/ohjelmasarja/${mediaSeriesId}`
      : `https://deiplus.fi/ohjelmasarja/${mediaSeriesId}#b${itemId}`
    try {
      await navigator.clipboard.writeText(linkText)
      setCopySuccess('Kopioitu!')
    } catch (err) {
      setCopySuccess('Error')
    }
  }

  useEffect(() => {
    setCopySuccess('Kopioi')
  }, [mediaSeriesId, xmlId])

  return (
    <div style={{ marginLeft: 10 }}>
      <FavoriteButtonContainer onClick={handleCopyClick} marginTop={marginTop} inverse={inverse}>
        <Text inverse={inverse}>{copySuccess}</Text>
      </FavoriteButtonContainer>
    </div>
  )
}

export default CopyLinkButton
