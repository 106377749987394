import React, { createRef } from 'react';
import { ExpandContainer, ExpandText, CollapsibleContainer, TitleText, TitleWrapper, DateContainer, DuraPublishSpan } from './MediaFilesCollapsibleStyles';
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { colors } from '../../styles/variables'
import moment from 'moment'

interface IMediaFilesCollapsibleHeader {
  onClick: Function;
  title: string;
  isOpen: boolean;
  id: string;
  date: Date
  mediaSeriesId: number
  URI: string
  showName: string
  duration: number
  mediaType?: string
}

const MediaFilesCollapsibleHeader: React.FC<IMediaFilesCollapsibleHeader> = ({
  onClick, title, isOpen, id, date
}) => {
  const header = createRef<HTMLDivElement>();
  const delay = async (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

  const onHeaderClick = async () => {
    onClick(id);
    if (header.current) {
      const immutable = Object.freeze({ ref: header.current });
      await delay(250);
      immutable.ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  return (

    <div ref={header} id={id}>
      <CollapsibleContainer onClick={onHeaderClick}>
        <TitleWrapper>
          <DateContainer>
            <DuraPublishSpan>{moment(date).format('DD.MM.yyyy')}</DuraPublishSpan>
          </DateContainer>
          <TitleText>{title}</TitleText>
        </TitleWrapper>
        {isOpen ? (
          <ExpandContainer>
            <ExpandText>Sulje</ExpandText>
            <ExpandLess fontSize="large" style={{ color: colors.black }} />
          </ExpandContainer>
        ) : (
          <ExpandContainer>
            <ExpandText>Jakson tiedot</ExpandText>
            <ExpandMore fontSize="large" style={{ color: colors.black }} />
          </ExpandContainer>
        )}
      </CollapsibleContainer>
    </div>
  );
};

export { MediaFilesCollapsibleHeader };
