import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../styles/variables'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding: 0 10px;
  }
`

export const ExpandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 150px;
`

export const CollapsibleContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 30px;
  align-items: baseline;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column;
    padding: 20px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column;
  }
`
export const DateContainer = styled.div`
  min-width: 90px;
  margin: 0 30px 0 15px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    margin-left: 0;
  }
`

export const DuraPublishSpan = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: ${colors.ui.hrcolor};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    text-align: left;
  }
`
export const TitleText = styled.div`
  font-family: ${fonts.montserrat};
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding-top: 6px;
  }
`
export const ExpandText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: ${colors.ui.hrcolor};
`

export const VideoButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 10;
  background-color: ${colors.black};
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 100px;
  cursor: pointer;
  transition: 250ms all;
  border: none;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 38px;
    height: 38px;
  }
`

export const PlayButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0px 30px 5px 30px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    margin: 30px 0 0 15px;
  }
`

export const PlayText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.black};

  @media ${`(min-width: ${breakpoints.md}px)`} {
    font-size: 14px;
    line-height: 17px;
  }
`

export const LeftContainer = styled(DateContainer)`
  @media ${`(max-width: ${breakpoints.sm}px)`} {
    display: none;
  }

  @media ${`(max-width: ${breakpoints.lg}px)`} {
    margin: 0 15px;
    min-width: 0;
  }
`

export const Image = styled.img`
  height: 300px;
  align-self: flex-start;
  margin-bottom: 35px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px 43px 30px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding: 0 20px 30px 20px;
  }

  @media ${`(min-width: ${breakpoints.md}px)`} {
  }

  @media ${`(min-width: ${breakpoints.xl}px)`} {
  }

  @media ${`(min-width: 1470px)`} {
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EpisodeDescription = styled.p`
  font-family: ${fonts.codeProMono};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  white-space: pre-line;
  align-self: flex-start;
  word-break: break-word;
  padding-right: 20%;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding-right: 0;
  }
`

export const FavoriteContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    align-items: flex-start;
    flex-direction: column;
  }
`

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ShareText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  margin-bottom: 4px;
`
