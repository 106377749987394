import React, { useEffect, useRef, useState } from 'react'
import {
  LeftContainer,
  Image,
  Container,
  DescriptionContainer,
  EpisodeDescription,
  FavoriteContainer,
  ShareContainer,
  ShareText
} from './MediaFilesCollapsibleStyles'
import { observer } from 'mobx-react-lite'
import FavoriteButton from '../FavoriteButton'
import CopyButton from '../CopyButton'
import SomeButtons from '../SomeButtons/SomeButtons'
import styled from 'styled-components'
//@ts-ignore
import Video from '../../resources/icons/video-icon.svg'
import { useMst } from '../../models/Root'
import { VideoButton, PlayButtonContainer, PlayText } from './MediaFilesCollapsibleStyles'
import { useVideoPlayer } from '../../utils/VideoPlayerContext'
import { colors, breakpoints } from '../../styles/variables'
import { useMediaQuery } from 'react-responsive'

const TransitionWrapper = styled.div`
  overflow: hidden;
  transition: height 250ms ease-in-out;
`

interface Props {
  title: string
  duration: number | undefined
  publishStart: Date
  description: string
  imageSrc: string | undefined
  id: number
  xmlId?: number
  showName: string
  URI: string
  originalUrl: string
  mediaSeriesId: number
  mediaType?: string
  isOpen: boolean
  relatedVideo?: any[]
}

export const MediaFileCollapsibleContent: React.FC<Props> = observer(
  ({ id, description, imageSrc, mediaSeriesId, mediaType, isOpen, relatedVideo, xmlId }) => {
    const [height, setHeight] = useState<number>(0)
    const innerRef = useRef<HTMLDivElement>(null)
    const { setVideoOpen, setVideoUrl } = useVideoPlayer()
    const { mediaPlayer } = useMst()
    const isMobile = useMediaQuery({ maxWidth: breakpoints.xl })
    const styles = {
      videoIconSmall: {
        width: 23,
        height: 23
      },
      videoIconLarge: {
        width: 40,
        height: 40
      }
    }
    const playVideo = (url: string) => {
      setVideoOpen(true)
      setVideoUrl(url)
      mediaPlayer.setPause(true)
    }

    const renderRelatedVideo = () => {
      if (!relatedVideo || !Array.isArray(relatedVideo)) {
        return null
      }

      return relatedVideo.map((video, index) => (
        <div key={index}>
          <h2 style={{ marginTop: 30, marginBottom: 20 }}>Katso aiheeseen liittyvä video</h2>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            {video.thumbnailUrl && <Image src={video.thumbnailUrl} onClick={() => playVideo(video.originalUrl)} />}
            <PlayButtonContainer>
              <VideoButton onClick={() => playVideo(video.originalUrl)}>
                <Video fill={colors.white} style={isMobile ? styles.videoIconSmall : styles.videoIconLarge} />
              </VideoButton>
              <PlayText>katsele</PlayText>
            </PlayButtonContainer>
          </div>
        </div>
      ))
    }

    /*
      if (relatedVideo && relatedVideo.originalUrl) {
        return (
          <div>
            <h2 style={{ marginTop: 30, marginBottom: 20 }}> Katso aiheeseen liittyvä video </h2>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              {imageSrc && <Image src={relatedVideo.thumbnailUrl} onClick={() => playVideo(relatedVideo.originalUrl)} />}
              <PlayButtonContainer>
                <VideoButton onClick={() => playVideo(relatedVideo.originalUrl)}>
                  <Video fill={colors.white} style={isMobile ? styles.videoIconSmall : styles.videoIconLarge} />
                </VideoButton>
                <PlayText>katsele</PlayText>
              </PlayButtonContainer>
            </div>
          </div>
        )
      }
      */

    useEffect(() => {
      if (isOpen && innerRef.current) {
        setHeight(innerRef.current.getBoundingClientRect().height)
      } else {
        setHeight(0)
      }
    }, [isOpen])
    const itemId = xmlId ?? id
    return (
      <TransitionWrapper style={{ height }}>
        <Container ref={innerRef}>
          <LeftContainer />
          <DescriptionContainer>
            {imageSrc && <Image src={imageSrc} />}
            <EpisodeDescription>{description}</EpisodeDescription>
            {renderRelatedVideo()}
            {mediaType !== 'video' && (
              <FavoriteContainer>
                <ShareContainer>
                  <ShareText>Jaa jakso someen</ShareText>
                  <SomeButtons url={`https://deiplus.fi/ohjelmasarja/${mediaSeriesId}#b${itemId}`} />
                </ShareContainer>
                <FavoriteButton series={false} id={id} marginTop={20} />
                <CopyButton series={false} id={id} marginTop={20} mediaSeriesId={mediaSeriesId.toString()} xmlId={itemId.toString()} />
              </FavoriteContainer>
            )}
          </DescriptionContainer>
        </Container>
      </TransitionWrapper>
    )
  }
)
