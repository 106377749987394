import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Page from '../components/Page'
import { getMediaSeriesById } from '../services/api'
import FavoriteButton from '../components/FavoriteButton'
import CopyButton from '../components/CopyButton'
import Container from '../components/Container'
import SomeCard from '../components/SomeCards'
import SeriesPageHeader from '../components/SeriesPageHeader'
import FooterBanner from '../components/DynamicBanner/FooterBanner'
import { MediaSeriesFilesCollapsible } from '../components/MediaSeriesFilesCollapsible'
import styled from 'styled-components'
import { breakpoints, colors, fonts } from '../styles/variables'
import SomeButtons from '../components/SomeButtons/SomeButtons'
import analytics from '../analytics'
import { logEvent } from 'firebase/analytics'

const SeriesContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
  overflow-y: hidden;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
    width: 100wv;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100wv;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`
const FavoriteContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-self: flex-end;
  width: auto;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    justify-content: center;
    background: ${colors.black};
  }
`
const MediaSeriesContainer = styled.div`
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1150px;
  margin-top: 100px;
  justify-content: flex-start;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100%;
    max-width: 850px;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100wv;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`
const MoreButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-itmes: center;
  background: none;
  border: none;
  margin-left: 20px;
  margin-top: 20px;
`

const ButtonText = styled.div`
  margin: auto 0 auto 4px;
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  color: ${colors.deiRed};
`

const MediaSeriesPage: FC<any> = observer((props) => {
  const [apiResult, setApiResult] = useState(null)
  const [apiResultFetched, setApiResultFetched] = useState(false)
  const [mediaType, setMediaType] = useState('')
  const mediaSeriesId = props['*']

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'screen_visited', {
        screen: 'ohjelmasarja'
      })
    }
  }, [])

  useEffect(() => {
    getMediaSeriesById(mediaSeriesId, mediaType).then((data) => {
      setApiResult(data.data)
      setApiResultFetched(true)
      if (apiResult && apiResult.videos?.length) {
        setMediaType('video')
      } else setMediaType('undefined')
    })
    console.log(apiResult)
  }, [apiResultFetched])

  const renderResults = () => {
    if (apiResultFetched) {
      return (
        <MediaSeriesFilesCollapsible
          files={mediaType === 'video' ? apiResult?.videos : apiResult?.mediaFiles}
          mediaType={mediaType}
          mediaSeriesId={parseInt(mediaSeriesId)}
        />
      )
    }
  }

  return (
    <Page>
      <SomeCard title={apiResult?.name} image={apiResult?.imageSrc} description={apiResult?.description} />
      <SeriesContainer>
        <ButtonContainer>
          <MoreButton onClick={() => {}}>
            <ButtonText>Lisää samankaltaisia</ButtonText>
          </MoreButton>
        </ButtonContainer>
        <HeaderContainer>
          <SeriesPageHeader title={apiResult?.name ?? 'Untitled'} imageSrc={apiResult?.imageSrc} description={apiResult?.description} />
          <div style={{ display: 'flex' }}>
            <SomeButtons url={`https://deiplus.fi/ohjelmasarja/${mediaSeriesId}`} />
            {mediaType !== 'video' && <FavoriteButton series={true} id={Number(props['*'])} marginTop={10} />}
            <CopyButton series={true} id={apiResult?.id} marginTop={10} mediaSeriesId={mediaSeriesId.toString()} xmlId={undefined} />
          </div>
        </HeaderContainer>
        <MediaSeriesContainer>{renderResults()}</MediaSeriesContainer>
        <FooterBanner />
      </SeriesContainer>
    </Page>
  )
})
export default MediaSeriesPage
