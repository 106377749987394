import React, { FC, useState, useEffect } from 'react'
import { VideoButton, PlayButtonContainer, PlayText } from './MediaFilesCollapsibleStyles'
import { useVideoPlayer } from '../../utils/VideoPlayerContext'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../models/Root'
import styled from '@emotion/styled'
import { MediaFilesCollapsibleHeader } from './MediaFileCollapsibleHeader'
import { MediaFileCollapsibleContent } from './MediaFileCollapsibleContent'
// @ts-ignore
import Video from '../../resources/icons/video-icon.svg'
import { PlayButton } from '../PlayButton'
import { colors, breakpoints } from '../../styles/variables'
import { useMediaQuery } from 'react-responsive'

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

interface IMediaSeriesFilesCollapsibleProps {
  files: IMediaFileAudioProps[]
  mediaType: string
  mediaSeriesId: number
}

interface IMediaFileAudioProps {
  URI: string
  originalUrl: string
  description: string
  duration: number
  id: number
  publishStart: Date
  title: string
  type: string
  xmlId: number
  showName: string
  imageSrc?: string
  mediaSeries: any[]
  premierDate: Date
  mediaType: string
  videos?: any[]
}

export const MediaSeriesFilesCollapsible: FC<IMediaSeriesFilesCollapsibleProps> = observer((props) => {
  const { files } = props

  const [openItem, setOpenItem] = useState<number | null>(null)
  const isOpen = (id: number) => openItem == id
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl })
  const { mediaPlayer } = useMst()
  const { videoOpen, setVideoOpen, videoUrl, setVideoUrl } = useVideoPlayer()
  const playVideo = (url: string) => {
    setVideoOpen(true)
    setVideoUrl(url)
    mediaPlayer.setPause(true)
    console.log(mediaPlayer.paused)
  }

  const styles = {
    videoIconSmall: {
      width: 23,
      height: 23
    },
    videoIconLarge: {
      width: 40,
      height: 40
    }
  }

  const renderButton = (
    mediaType: string,
    URI: string,
    originalUrl: string,
    id: number,
    showName: string,
    title: string,
    duration: number,
    mediaSeriesId: number
  ) => {
    if (mediaType === 'video') {
      return (
        <PlayButtonContainer>
          <VideoButton onClick={() => playVideo(originalUrl)}>
            <Video fill={colors.white} style={isMobile ? styles.videoIconSmall : styles.videoIconLarge} />
          </VideoButton>
          <PlayText>katsele</PlayText>
        </PlayButtonContainer>
      )
    }
    return (
      <PlayButtonContainer>
        <PlayButton
          key={id}
          mediaId={id}
          showName={showName}
          mediaSrc={decodeURIComponent(URI).includes('http') ? URI : 'https://jako.dei.fi/ondemand/lowbit/' + URI}
          title={title}
          duration={duration}
          mediaSeriesId={mediaSeriesId}
          buttonColor={colors.white}
          backgroundColor={colors.red}
        />
        <PlayText>kuuntele</PlayText>
      </PlayButtonContainer>
    )
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      const url = window.location.href
      const currentItem = files?.find((item) => url.endsWith(`#b${item.xmlId}`))
      if (currentItem) {
        setOpenItem(currentItem.xmlId)
      }
    }
  }, [files])

  const handleClick = (id: number) => {
    if (id === openItem) {
      setOpenItem(null)
      return
    }
    setOpenItem(id)
  }

  return (
    <List>
      {files?.map((file: IMediaFileAudioProps) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ width: '100%', marginBottom: '20px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)' }}>
              <li key={file.id}>
                <MediaFilesCollapsibleHeader
                  mediaType={file.mediaType}
                  onClick={() => handleClick(file.xmlId ?? file.id)}
                  isOpen={isOpen(file.id)}
                  date={file.publishStart}
                  title={file.title}
                  id={`b${file.xmlId}`}
                  mediaSeriesId={props.mediaSeriesId}
                  URI={file.URI}
                  showName={file.showName}
                  duration={file.duration}
                />

                <MediaFileCollapsibleContent
                  isOpen={isOpen(file.xmlId ?? file.id)}
                  title={file.title}
                  duration={file.duration}
                  publishStart={file.publishStart}
                  description={file.description}
                  id={file.id}
                  xmlId={file.xmlId}
                  imageSrc={file.imageSrc}
                  showName={file.showName}
                  mediaSeriesId={props.mediaSeriesId}
                  URI={file.URI}
                  originalUrl={file.originalUrl}
                  relatedVideo={file.videos}
                />
              </li>
            </div>
            {renderButton(
              props.mediaType,
              file.URI,
              file.originalUrl,
              file.id,
              file.showName,
              file.title,
              file.duration,
              props.mediaSeriesId
            )}
          </div>
        )
      })}
    </List>
  )
})
